import React from "react";
import Background from "../../Background/Background";
import { graphql } from "gatsby";
import Personalization from "../../Personalization/Personalization";
import ContentRenderer from '../../ContentRenderer/ContentRenderer';

const MultipleModule = ({ block }) => {
  const anchorLinkId = block.anchorlinkid
  const modules = block.modules;
  const height = block.height;
  const bgColor = block.backgroundColor;
  const hasGradient = block.hasgradient
  const visibleTo = [];
  let usePersonalization = false;

  if (block.modules) {
    block.modules.forEach(({ visibleFor }) => {
      if (!(visibleFor === undefined)) {
        visibleTo.push(visibleFor);
        if (modules.length === visibleTo.length) {
          usePersonalization = true;
        } else {
          usePersonalization = false;
        }
      }
    });
  }

  return (
    <Personalization visibleFor={visibleTo} usePersonalization={usePersonalization}>
      <Background height={height} bgColor={bgColor} hasGradient={hasGradient} anchorLinkId={anchorLinkId}>
        <ContentRenderer blocks={modules} />
      </Background>
    </Personalization>
  );
};

export default MultipleModule;

export const query = graphql`
  fragment MultipleModule on DatoCmsMultiplemodule {
    model {
      apiKey
    }
    id: originalId
    anchorlinkid
    height
    backgroundColor {
      hex
    }
    hasgradient
    modules {
      ...BookADemo
      ...FeatureOverview
      ...FormReference
      ...Ipad
      ...List
      ...RichText
      ...RichTextTwoCol
      ...TextImage
      ...TitleBox
      ...TitleColumns
      ...TitleSubtitle
      ...TitleText
      ...TrustedBy
      ...PageGallery
      ...QuickInfo
    }
  }
`;

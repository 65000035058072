import React, { useRef, useEffect, useState } from "react";
import cn from "classnames"
import colors from "lib/colors";
import * as BackgroundStyling from "./Background.module.scss";

const Background = ({ anchorLinkId, children, height, bgColor, viewHeight, hasGradient }) => {
  const hasDarkBackground = bgColor && colors.isDarkColor(bgColor.hex);
  const [isHeroContent, setIsHeroContent] = useState(false);
  const backgroundRef = useRef();
  const isViewHeight = viewHeight === true;

  useEffect(() => {
    if (backgroundRef.current && backgroundRef.current.offsetTop < 100) {
      setIsHeroContent(true);
    }
  }, []);

  return (
    <div
      id={anchorLinkId}
      ref={backgroundRef}
      className={`backgroundWrapper 
      ${BackgroundStyling.backgroundWrapper}
      ${hasDarkBackground ? "mono-container" : ""}`}
      style={{ paddingTop: isHeroContent && "0" }}
    >
      {isHeroContent && (
        <span
          className={BackgroundStyling.before}
          style={{ backgroundColor: bgColor ? bgColor.hex : "transparent" }}
        ></span>
      )}
      <div
        className={cn(BackgroundStyling.background, { [BackgroundStyling.background__gradient]: hasGradient })}
        style={{
          backgroundColor: bgColor ? bgColor.hex : "transparent",
          height: isViewHeight ? `${height}vh` : `${height}%`,
          minHeight: isViewHeight && `${height}rem`,
        }}
      ></div>
      {children}
    </div >
  );
};

export default Background;
